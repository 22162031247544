export default {
  checkParticipation(participation) {
    return participation !== null && (participation.status === 'rejected_lead' || participation.status === 'pending_lead');
  },
  getButtonText(participation) {
    let text = 'generalLeads.apply';
    if (participation === null || participation === undefined) return text;

    switch (participation.status) {
    case 'pending_lead':
      text = 'generalLeads.noDesktop';
      break;

    case 'approved_lead':
      text = 'generalLeads.goDesktop';
      break;

    case 'rejected_lead':
      text = 'generalLeads.noDesktop';
      break;
    }

    return text
    
  },
  getAlertText(participation) {
    let text = 'generalLeads.apply';

    if (participation === null || participation === undefined) return text

    switch (participation.status) {
    case 'pending_lead':
      text = 'generalLeads.pending';
      break;

    case 'approved_lead':
      text = 'generalLeads.approved';
      break;

    case 'rejected_lead':
      text = 'generalLeads.rejected';
      break;
    }

    return text;
  },
  activeDesktop(participation) {
    if (participation === null || participation === undefined) return true;

    if (participation.status === 'approved_lead') return true

    return false;
  }
}