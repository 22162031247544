<template>
  <section>
    <!-- DASHBOARD BRAND-->
    <dashboard-Brand v-if="groupRol !== 'creator'"></dashboard-Brand>
    <!-- DASHBOARD CREATOR-->
    <dashboard-Creator v-if="groupRol === 'creator'" class="mt-1"></dashboard-Creator>
  </section>
</template>

<script>
import DashboardBrand from '@/views/dashboard/analytics/DashboardBrand.vue'
import DashboardCreator from '@/views/dashboard/analytics/DashboardCreator.vue'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import service_leads from '@/services/leads'
import { toCurrency } from '@/libs/utils/formats'

export default {
  components: {
    DashboardBrand,
    DashboardCreator,
  },
  beforeCreate () {
    const user = JSON.parse(localStorage.userData)
    this.meta_info = user.meta_info 
    const route = getHomeRouteForLoggedInUser(user)
    const redirect = route.name === 'terms-conditions' || route.name === 'account-company' || route.name === 'auth-login' || route.name === 'auth-roll'

    if (redirect) this.$router.push(route)
    
    this.groupRol = user.main_group.name;

    if (this.groupRol === 'realty') this.$router.push({name: 'campaigns'})

    service_leads.getDetail('grandmaster').then(response => {
      this.participation_lead = response.participation;
      this.total_balance = response.participation ? toCurrency(response.participation.available_amount) : toCurrency(0);
    });
  },
};
</script>
<style scoped>
.subtitle-alert-creator {
  color: white;
}
</style>